/* CSS for the Posts Component */

button {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 13px;
    background: none;
    color: #333;
    border: none;
    padding: 8px 14px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.toggle-icon {
    color: #6a6a6a;
}

button:hover {
    transform: scale(1.05);
    background-color: #f0f0f0;
}

button.active {
    color: #6867af;
}


.view-toggle {
    margin-bottom: 20px;
}

.posts-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.posts-card a {
    max-width: 400px;
}

.post-item {
    position: relative;
    overflow: hidden;
    max-width: 400px;
    padding: 20px;
    /* background-color: #f9f9f9; */
    background-color: var(--post-background);
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.post-item:hover {
    transform: scale(1.05);
    background-color: var(--post-background-highlight);
}

.post-image-container {
    position: relative;
    height: 150px;
}

.post-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.post-image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #3433527d; */
    background-color: var(--post-background-highlight);
    z-index: 1;
    transition: opacity 0.3s ease;
    opacity: 0;
    border-radius: 10px;
}

.post-item:hover .post-image-container::before {
    opacity: 1;
}

.post-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: white;
    font-size: 20px;
    z-index: 2;
    pointer-events: none;
}

.post-item:hover .post-overlay {
    opacity: 1;
}

.post-title {
    display: flex;
    font-size: 18px;
    text-decoration: none;
    color: var(--text-color);
}

.post-item .post-title {
    justify-content: center;
    align-items: center;
    min-height: 60px;
    margin-top: 15px;
}

.post-item:hover .post-title {
    color: #6867af;
    transition: color 0.3s ease;
}

/*========== Post list css*/

/* List View Container */
.posts-line {
    display: flex;
    flex-direction: column;
    gap: 0;
    /* No gap between rows */
    width: 100%;
}

/* Each Row */
.post-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none !important;
    color: var(--text-color);
    padding: 13px 15px;
    border-radius: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

/* Alternating Backgrounds */
.post-line.even {
    /* color: #333; */
    /* background-color: #f9f9f9; */
    background-color: var(--post-background);
}

/* .post-line.odd {
    color: var(--text-color);
} */

.post-line:hover {
    background-color: var(--post-background-highlight);
}

/* 
.post-line.even:hover {
    color: var(--text-color);
} */

.post-line:hover .post-title {
    color: #6867af;
    transition: color 0.3s ease;
    text-decoration: underline;
}

/* Title and Date Alignment */
.posts-line .post-title {
    flex: 1;
    font-weight: 700;
}

.post-line .post-date {
    font-size: 14px;
    /* color: #555; */
    text-align: right;
    min-width: 100px;
}