:root {
  /*Toggle size*/
  --toggle-size: 75px;
  --toggle-width: var(--toggle-size);
  --toggle-height: calc(.5*var(--toggle-size));
  --toggle-border: calc(.025*var(--toggle-size));

  /* Light mode colors */
  --blue-border: #e6e7e7;
  --blue-color: #f0f0f0;
  --yellow-background: #fffaa8;
  --yellow-border: #f5eb71;

  /* Dark mode colors */
  --indigo-border: #5d6baa;
  --indigo-color: #6866af;
  --gray-border: #e8e8ea;
  --gray-dots: #e8e8ea;

  /* General */
  --light-background: #ffffff;
  --dark-background: #232324;
  --light-text-color: #333;
  --dark-text-color: #d4d4d8;
  --post-background: rgba(104, 103, 175, 0.05);
  --post-background-highlight: rgba(104, 103, 175, 0.2);
  --white: #ffffff;
}

body {
  --background-color: var(--light-background);
  --text-color: var(--light-text-color);
  transition: background-color 350ms ease-in;
}

/* Dark Mode */
body.dark-mode {
  --background-color: var(--dark-background);
  --text-color: var(--dark-text-color);
}

.toggle-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  display: none;
}

.toggle-container.visible {
  display: block;
}

@media (prefers-color-scheme: light) {
  .hover-area {
    pointer-events: auto;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 150px;
    height: 100px;
    z-index: 1;
  }

  .hover-area:hover+.toggle-container {
    display: block;
  }

  .toggle-container {
    pointer-events: none;
  }

  .toggle-container.visible {
    pointer-events: auto;
  }
}


/* Show the toggle only if the user prefers dark mode */
@media (prefers-color-scheme: dark) {
  .toggle-container {
    display: block;
  }
}

.theme-toggle-checkbox {
  display: none;
}

.theme-toggle-checkbox:checked~body {
  background-color: var(--dark-background);
}

.theme-toggle-checkbox:checked+.theme-toggle-label {
  background: var(--indigo-color);
  border-color: var(--indigo-border);
}

.theme-toggle-checkbox:checked+.theme-toggle-label .theme-toggle-label-background {
  left: calc(.6*var(--toggle-height));
  width: var(--toggle-border);
}

.theme-toggle-checkbox:checked+.theme-toggle-label .theme-toggle-label-background:before {
  width: var(--toggle-border);
  height: var(--toggle-border);
  top: calc(-5*var(--toggle-border));
}

.theme-toggle-checkbox:checked+.theme-toggle-label .theme-toggle-label-background:after {
  width: var(--toggle-border);
  height: var(--toggle-border);
  left: calc(-6*var(--toggle-border));
  top: calc(4*var(--toggle-border));
}

.theme-toggle-checkbox:checked+.theme-toggle-label:before {
  background: var(--white);
  border-color: var(--gray-border);
  animation-name: switch;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
}

.theme-toggle-checkbox:checked+.theme-toggle-label:after {
  transition-delay: 350ms;
  opacity: 1;
}

.theme-toggle-label {
  cursor: pointer;
  width: var(--toggle-width);
  height: var(--toggle-height);
  background: var(--blue-color);
  border-radius: calc(var(--toggle-height) / 2);
  border: var(--toggle-border) solid var(--blue-border);
  display: flex;
  position: relative;
  transition: all 350ms ease-in;
}

.theme-toggle-label-background {
  width: calc(var(--toggle-border)*2);
  height: var(--toggle-border);
  border-radius: var(--toggle-border);
  position: relative;
  background: var(--white);
  left: calc(var(--toggle-width)*0.67);
  top: calc(var(--toggle-height)*0.45);
  transition: all 150ms ease-in;
}

.theme-toggle-label-background:before {
  content: "";
  position: absolute;
  top: calc(-1*var(--toggle-border));
  width: calc(var(--toggle-border)*8);
  height: var(--toggle-border);
  border-radius: var(--toggle-border);
  background: var(--white);
  left: calc(-4*var(--toggle-border));
  transition: all 150ms ease-in;
}

.theme-toggle-label-background:after {
  content: "";
  position: absolute;
  top: var(--toggle-border);
  width: calc(var(--toggle-border)*8);
  height: var(--toggle-border);
  border-radius: var(--toggle-border);
  background: var(--white);
  left: calc(-2*var(--toggle-border));
  transition: all 150ms ease-in;
}

.theme-toggle-label:before {
  animation-name: reverse;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  content: "";
  width: calc(var(--toggle-height)*0.82);
  height: calc(var(--toggle-height)*0.82);
  border: var(--toggle-border) solid var(--yellow-border);
  top: calc(0.8*var(--toggle-border));
  left: calc(0.8*var(--toggle-border));
  position: absolute;
  border-radius: calc(var(--toggle-height)*0.82);
  background: var(--yellow-background);
}

.theme-toggle-label:after {
  transition-delay: 0ms;
  transition: all 250ms ease-in;
  position: absolute;
  content: "";
  box-shadow: var(--gray-dots) calc(-2.6*var(--toggle-border)) 0 0 calc(.5*var(--toggle-border)), var(--gray-dots) calc(-5*var(--toggle-border)) calc(3*var(--toggle-border)) 0 calc(-0.5*var(--toggle-border));
  left: calc(var(--toggle-width)*0.7);
  top: calc(var(--toggle-height)*0.23);
  width: calc(var(--toggle-height)*0.1);
  height: calc(var(--toggle-height)*0.1);
  background: transparent;
  border-radius: 50%;
  opacity: 0;
}

@keyframes switch {
  0% {
    left: calc(0.8*var(--toggle-border));
  }

  100% {
    left: calc(var(--toggle-width)/2);
    width: calc(var(--toggle-height)*0.82);
  }
}

@keyframes reverse {
  0% {
    left: calc(var(--toggle-width)/2);
    width: calc(var(--toggle-height)*0.82);
  }

  100% {
    left: calc(0.8*var(--toggle-border));
  }
}

/*
==========================================
*/

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

/* Apply Lato for titles and headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  /* color: #007bff; */
}




/* SECTION: About and Articles Container */
.page {
  display: flex;
  padding: 50px;
  justify-content: center;
  /* Center the divs horizontally */
  align-items: flex-start;
  /* Ensures the items align at the top */
}

/* About Me with Neomorphism Effect */
.about-me {
  position: sticky;
  top: 50px;
  flex: 1;
  max-width: 355px;
  /* Ensure it doesn't exceed the screen width */
  box-sizing: border-box;
  padding: 25px;
  /* Neomorphism */
  margin-right: 30px;
  /* Add space between the left and right sections */
}

.about-me h1 {
  color: #6867af;
  font-size: 34px;
  /* color: #69a7ba; */
}

/* .profile-pic {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
} */

/* Right Section Styling */
.right-section {
  flex: 2;
  max-width: 800px;
  width: 100%;
}

.right-section li {
  flex: 2;
  margin-bottom: 10px;
}

a {
  color: #6867af;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.navigation {
  display: flex;
  justify-content: flex-start;
  padding: 30px 20px;

}

/* Customize buttons */

li {
  list-style-type: disc;
}

.navigation .nav-button {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 13px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.navigation .nav-button:hover {
  transform: scale(1.05);
  text-decoration: none;
}

.navigation .nav-button.active {
  background-color: #6867af;
  color: #ffffff;
}

/* Content Containers */
.contentElement {
  padding: 20px;
  display: block;
}

.social-list {
  list-style-type: none;
  padding: 0;
  display: grid !important;
  grid-template-columns: repeat(6, auto);
  /* Attempt to fit all 6 items on one line */
  gap: 20px;
  /* Minimum gap between icons */
  width: 100%;
  justify-items: center;
  /* Center the icons horizontally */
  align-items: center;
  /* Center icons vertically */
}

@media (max-width: 1200px) {
  .social-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 850px) {
  :root {
    --toggle-size: 50px;
    /* Smaller size for screens below 768px */
  }

  .social-list {
    grid-template-columns: repeat(6, auto);
  }

  .page {
    flex-direction: column;
    padding: 0px;

    /* Stacks the sections vertically */
  }

  .about-me {
    max-width: 100%;

    width: 100%;
    position: static;
    margin-right: 0;
    /* Removes right margin to avoid extra space */
    margin-bottom: 20px;
    /* Adds space below the About Me section */
  }
}

.social-list li {
  font-size: 20px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-list li a {
  color: #6a6a6a;
}

.social-list li:hover {
  transform: scale(1.2);
}

.social-list li a:hover {
  color: #7978a2;
  /* transform: scale(.1); */
  /* Slightly enlarges the icon on hover */
}



/*Tool Tip*/
/* Position the tooltip container */
.social-list li {
  position: relative;
  display: inline-block;
}

.social-list li a::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -25px;
  /* Adjust position */
  left: 50%;
  transform: translateX(-50%);
  background-color: #343352c5;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  /* Prevents line breaks */
}

/* Show the tooltip on hover */
.social-list li a:hover::after {
  opacity: 1;
}